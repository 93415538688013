import { initializeApp } from "firebase/app";
import {
//   MessagePayload,
  // deleteToken,
  getMessaging,
  getToken,
} from "firebase/messaging";
import { firebaseConfig, vapidKey } from "controller/common/firebase";
import { updateFirebaseToken } from "./dbServices";
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseMessaging = getMessaging(firebaseApp);

export const initFirebaseMessaging = () => {
  getToken(firebaseMessaging, { vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        sendTokenToServer(currentToken);
      } else {
        requestPermission()
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
        setTokenSentToServer(false);
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
      setTokenSentToServer(false);
    });
}

async function sendTokenToServer(currentToken: string) {
  if (!isTokenSentToServer()) {
    localStorage.setItem("fcm-token", currentToken);
    await updateFirebaseToken(currentToken)
    setTokenSentToServer(true);
  } else {
    await updateFirebaseToken(currentToken)
    // console.log(
    //   "Token already sent to server so won't send it again unless it changes"
    // );
  }
}

function isTokenSentToServer() {
  return window.localStorage.getItem("sentToServer") === "1";
}

function setTokenSentToServer(sent: boolean) {
  window.localStorage.setItem("sentToServer", sent ? "1" : "0");
}

function requestPermission() {
  // console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      initFirebaseMessaging();
    } else {
      // console.log("Unable to get permission to notify.");
    }
  });
}

// function deleteTokenFromFirebase() {
//   // Delete registration token.
//   getToken(firebaseMessaging)
//     .then((currentToken) => {
//       deleteToken(firebaseMessaging)
//         .then(() => {
//           console.log("Token deleted.", currentToken);
//           setTokenSentToServer(false);
//           // Once token is deleted update UI.
//           initFirebseMessaging();
//         })
//         .catch((err) => {
//           console.log("Unable to delete token. ", err);
//         });
//     })
//     .catch((err) => {
//       console.log("Error retrieving registration token. ", err);
//     //   showToken("Error retrieving registration token.");
//     });
// }
