import CONFIG from "../../env/env.json";

export const firebaseConfig = {
  apiKey: CONFIG.FIREBASE.API_KEY,
  authDomain: CONFIG.FIREBASE.AUTH_DOMAIN,
  projectId: CONFIG.FIREBASE.PROJECT_ID,
  storageBucket: CONFIG.FIREBASE.STORAGE_BUCKET,
  messagingSenderId: CONFIG.FIREBASE.MESSAGING_SENDER_ID,
  appId: CONFIG.FIREBASE.APP_ID,
  measurementId: CONFIG.FIREBASE.MEASUREMENT_ID,
};

export const vapidKey = CONFIG.FIREBASE.VAPID_KEY;
